import React, { useRef } from 'react';
import Thumb from './Thumb';

import '../css/works.css';

function Works({ jsonData, bioData }) {
  const worksContainerRef = useRef(null);



  return (

    <div className="collections">
      {jsonData.map((collection, collectionIndex) => (
        <React.Fragment key={collectionIndex}>
        <div className="collection-title">
          <h1>{collection.title}</h1>
          <h2>{collection.date}</h2>
        </div>
        <div className="works" key={collectionIndex} ref={worksContainerRef}>
          {collection.works.map((item, index) => (
            <Thumb 
              key={`${collectionIndex}-${index}`}  
              data={item} 
              index={collectionIndex}
              id={index} 
            />
          ))}
        </div>
        </React.Fragment>
      ))}
    </div>
  );
}   

export default Works;